.bg-index {
    background: #172b4d;
}
.card-body {
    white-space: pre-line
}
@media (min-width: 1200px) {
    .separator-skew {
        height: 80px;
    }
}

h1, .h1 {
    font-size: 2rem;
}

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.fixed-left+.main-content {
        margin-left: 250px;
    }
}

.main-content {
    position: relative;
}

#navbar-main {
    margin-bottom: 3rem;
}

.footer {
    background: transparent;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.shop-card {
    margin: 20px 15px;
}

.shop-details {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.shop-info {
    max-width: 50%;
}

.profile-header {
    padding-bottom: 0;
}

/* .cards .student-card:first-child {
    margin-left: 0;
}
.cards .student-card:last-child {
    margin-right: 0;
} */
.cards .student-card img, .cards .card-stats img {
    max-width: 100%;
	height: auto;
}
.cards .student-card, .cards .card-stats, .cards .shop-card {
    margin: 0.5rem;
}

.cards .shop-card {
    flex-basis: 350px;
}

.cards .student-card {
    flex-basis: 250px;
}

.student-card {
    border: 0.4rem solid;
}

.navbar-vertical.navbar-expand-md .navbar-brand {
    padding-bottom: 0;
}

.chart-student-activities {
    height: 100%;
}

.chart-student-activities tbody {
    line-height: 2.5rem;
}

.chart-student-activities .table td .progress {
    height: 8px;
}

.past-projects .badge:first-child, .past-projects .badge:last-child {
    margin: 0;
}

.past-projects .badge {
    margin: 0 0.5rem;
}

.holy-btn {
    font-size: 1.5rem;
    border: 0 solid;
}

.pointer {
    cursor: pointer;
}

.help-required .d-flex button {
    flex: auto;
}

.help-required .badge {
    font-size: 0.75rem;
}

.help-required .card-body {
    padding: 3rem;
}

.progress-divided:first-child {
    border-left: 0 solid;
}

.progress-divided:last-child {
    border-right: 0 solid;
}

.progress-divided {
    border-right: 2px solid;
    border-left: 1px solid;
}

.mood {
    font-size: 3rem;
}

.mood i:first-child {
    color: #ffd600;
}

.mood i:last-child {
    color: #2dce89;
}

.progress-g {
    /* background-color: #FAC8D5; */
    background-color: #f6d365;
}
.progress-b {
    /* background-color: #9F99EE; */
    background-color: #fda085 ;
}
.progress-empty {
    background-color: #fff;
}

.chart-student-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.chart-student-legend .sexe-legend {
    width: 30%;
    margin: 0;
    display: flex;
    align-items: center;
}

.chart-student-legend .progress {
    width: 100%;
    margin: 0;
}

.student-mars {
    margin-right: 0.5rem;
    color: #fda085;
}

.student-venus {
    color: #f6d365;
    margin-left: 0.5rem;
}

.btn-lg.btn-block.btn + .btn-lg.btn-block.btn {
    margin-left: 0;
}

.project-subtitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}