.user-avatar {
    text-align: center;
}

.user-avatar img {
    max-width: 140px;
    border-radius: .375rem;
    border: 3px solid #fff;
    /* position: absolute; */
    /* left: 50%; */
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}

.user-avatar img:hover {
    -webkit-transform:  scale(1.03);
    transform:  scale(1.03);
}
