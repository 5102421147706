.projectName {
    white-space: nowrap;
}

.projectDescription {
    white-space: normal!important;
    text-align: justify;
}

.projectDescription.card {
	position: absolute;
	top: 0;
	right: 5%;
	width: 20rem;
}

.projectDescription .card-header {
	padding-bottom: 0;
}

.holygraph {
	position: relative;
}

// background
 
body {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#094c72+98,01293f+100 */
	background: rgb(9,76,114);
	background: -webkit-radial-gradient(bottom, ellipse cover,  rgb(9,76,114) 0%,rgb(1,41,63) 100%);
	background: -webkit-radial-gradient(ellipse at bottom, rgb(9,76,114) 0%, rgb(1,41,63) 100%);
	background: -webkit-radial-gradient(bottom, ellipse, rgb(9,76,114) 0%, rgb(1,41,63) 100%);
	background: radial-gradient(ellipse at bottom,  rgb(9,76,114) 0%,rgb(1,41,63) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#094c72', endColorstr='#01293f',GradientType=1 );
	min-height: 100vh;
}

.holygraph h1 {
	color: white;
}

@function box-shadow($stars) {
	$bxshadow: ();
	@for $i from 1 to $stars {
		$bxshadow: append($bxshadow, (random(2000) + 0px) (random(2000) + 0px) #fff, comma);
	}
	@return unquote($bxshadow);
}

$bxshadow-large: box-shadow(300);
$bxshadow-medium: box-shadow(500);
$bxshadow-small: box-shadow(800);

.stars.large {
	height: 3px;
	width: 3px;
	background: transparent;
	box-shadow: $bxshadow-large;
	animation: blink-large 7s infinite;
}
.stars.medium {
	height: 2px;
	width: 2px;
	background: transparent;
	box-shadow: $bxshadow-medium;
	animation: blink-medium 5s infinite;
}
.stars.small {
	height: 1px;
	width: 1px;
	background: transparent;
	box-shadow: $bxshadow-small;
	animation: blink-small 3s infinite;
}

@keyframes blink-large {
	0% {
		box-shadow: $bxshadow-large;
	}	
	70% {
		$colors: '#fff', 'transparent';
		$stars: $bxshadow-large;
		@for $i from 1 to length($stars) {
			$star: set-nth(nth($stars, $i), 3, unquote(nth($colors, random(length($colors)))));
			$stars: set-nth($stars, $i, $star);
		}
		box-shadow: $stars;
	}
	100% {
		box-shadow: $bxshadow-large;
	}
}
@keyframes blink-medium {
	0% {
		box-shadow: $bxshadow-medium;
	}	
	70% {
		$colors: '#fff', 'transparent';
		$stars: $bxshadow-medium;
		@for $i from 1 to length($stars) {
			$star: set-nth(nth($stars, $i), 3, unquote(nth($colors, random(length($colors)))));
			$stars: set-nth($stars, $i, $star);
		}
		box-shadow: $stars;
	}
	100% {
		box-shadow: $bxshadow-medium;
	}
}
@keyframes blink-small {
	0% {
		box-shadow: $bxshadow-small;
	}	
	70% {
		$colors: '#fff', 'transparent';
		$stars: $bxshadow-small;
		@for $i from 1 to length($stars) {
			$star: set-nth(nth($stars, $i), 3, unquote(nth($colors, random(length($colors)))));
			$stars: set-nth($stars, $i, $star);
		}
		box-shadow: $stars;
	}
	100% {
		box-shadow: $bxshadow-small;
	}
}


