@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
        padding: 1.5rem 1.5rem!important;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
        border-left: 5px solid #fda085!important;
    }
}
.navbar-vertical .navbar-nav .nav-link, .navbar-vertical .navbar-nav .nav-link > i {
    font-size: 1.4rem!important;
}

.navbar-vertical .navbar-nav .nav-link.active:before {
    border-left: 5px solid #fda085!important;
}

.text-orange {
    color: #fda085;
}

/* @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.fixed-left {
        left: 0;
        border-width: 0 1px 0 0;
    }

    .navbar-vertical.navbar-expand-md {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 250px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        overflow-y: auto;
    }

    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-vertical.navbar-expand-md > [class*="container"] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-vertical.navbar-expand-md>[class*=container] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }

    .navbar-vertical.navbar-expand-md .navbar-brand {
        display: block;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 2.5rem;
    }
    
    .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap;
    }
}







.navbar-vertical {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.bg-white {
    background-color: #fff !important;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
}

.navbar > .container, .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: transparent;
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: initial;
    border: 1px solid transparent;
    border-radius: .375rem;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%;
}

.navbar-vertical .navbar-brand {
    margin-right: 0;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0,0,0,.9);
}

.pt-0, .py-0 {
    padding-top: 0!important;
}

.navbar-brand {
    display: inline-block;
    padding-top: .0625rem;
    padding-bottom: .0625rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

a {
    color: #5e72e4;
    text-decoration: none;
    background-color: initial;
    -webkit-text-decoration-skip: objects;
}

.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand>img {
    max-width: 100%;
    max-height: 2rem;
}

.navbar, .navbar>.container, .navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
} */